/*
  modal
*/
@import '../../styles/core/media.css';

.modal {
  position: absolute !important;
}

.bg {
  background: rgba(0, 0, 0, .6);
}

.content {
  background: rgba(25, 25, 33, .9);
  border: 1px solid var(--gray2);
  border-radius: 4px;
  height: 1px;
  overflow: hidden;
  width: 1px;
}

.content * {
  opacity: 0;
}

.fadeIn .content {
  height: 32rem;
  width: 320px;
  transition: width .25s var(--ease-out), height .5s var(--ease-out) .25s;
}

@media (--desktop) {
  .fadeIn .content {
    height: 28rem;
  }
}

.fadeIn .content * {
  opacity: 1;
  transition: opacity .75s .5s;
}

.close {
  color: var(--blue4);
  position: absolute;
  right: 20px;
  top: 20px;
}
