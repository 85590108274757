
.container {
  overflow: hidden;
  /* transition: background-color .275s; */
  transition: background-color 0s linear 0.05s;
}

.container.highlight {
  background: rgba(255,255,255,.1);
}
