:root {
  /* --orange: #cc8444;
  --green: #4b6; */
  --ease-out: cubic-bezier(0,.57,.43,.99);
  --ease-in-out: cubic-bezier(.42, .01, .16, .96);

  --bg-url: ""; /* url('/public/assets/bg.jpg') */
  --bg-opacity: 0;
  
  --black: #000;
  --gray1: #383845;
  --gray1-5: #535361;
  --gray2: #666;
  --gray3: #999;
  --gray4: #D2D2D4;
  --gray5: #fff;
  --white: #fff;

  --blue1: #191921;
  --blue2: #202029;
  --blue3: #24242f;
  --blue3-5: #2d2d3d;
  --blue4: #46c;
  --blue5: #4b72ea;

  --red1: #c46;
  --red2: #d35;

  --purple1: #773390;
  --purple2: #8b3da7;
  --purple3: #a4b;
}
