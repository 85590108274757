
:root {
  --pot-diameter: 61px;
}

.pot {
  border: 1px solid var(--gray4);
  border-radius: calc(var(--pot-diameter) / 2);
  position: relative;
  height: var(--pot-diameter);
  width: var(--pot-diameter);
}

.notch {
  background: var(--gray4);
  height: calc(var(--pot-diameter) / 6);
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
}
