
.input {
  appearance: none;
  background-color: transparent;
  border: 1px solid var(--gray4);
  border-radius: 4px;
  color: var(--gray4);
  outline: none;
  padding: .33rem;
}

.input.lg {
  padding: .75rem;
}

.input.short {
  width: 2.667rem;
}

.input.short.lg {
  width: 3.5rem;
}
