
@import '../../../styles/core/media.css';

.stepPad {
  float: left;
  position: relative;
  width: 6.25%;
}

.stepPadSquare {
  border: 1px solid var(--gray1);
  box-sizing: border-box;
  position: relative;
  transition: background-color .4s;
  width: 100%;
}
.stepPad:first-child .stepPadSquare,
.stepPad:nth-child(9) .stepPadSquare {
  border-left-color: var(--gray2);
}
.stepPad:nth-child(8) .stepPadSquare,
.stepPad:last-child .stepPadSquare {
  border-right-color: var(--gray2);
}

.stepPad.step0 .stepPadSquare,
.stepPad.step4 .stepPadSquare,
.stepPad.step8 .stepPadSquare,
.stepPad.step12 .stepPadSquare,
.stepPad.step16 .stepPadSquare,
.stepPad.step20 .stepPadSquare,
.stepPad.step24 .stepPadSquare,
.stepPad.step28 .stepPadSquare {
  border-top-color: var(--gray1-5);
}

.stepPad.step3 .stepPadSquare,
.stepPad.step7 .stepPadSquare,
.stepPad.step11 .stepPadSquare,
.stepPad.step15 .stepPadSquare,
.stepPad.step19 .stepPadSquare,
.stepPad.step23 .stepPadSquare,
.stepPad.step27 .stepPadSquare,
.stepPad.step31 .stepPadSquare {
  border-bottom-color: var(--gray1-5);
}

.stepPad:hover .stepPadSquare {
  border-color: var(--white);
}

.stepPadSquare:before {
  background: linear-gradient(to top, #000, #ff0000);
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.stepPadSquare.isActive {
  background-color: var(--purple1);
  transition: background-color 0 .01s;
  /* transition: background-color .275s; */
}

.stepPadSquare.isActive:before {
  opacity: 0.05;
}

.stepPadSquare.isActive.isHighlighted {
  background-color: var(--red1);
}

.stepPadSquare.isActive.isHighlighted:before {
  opacity: 0.1;
}
