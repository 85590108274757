/* flex bugfix */
* {
  flex-shrink: 1;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* apply styles to elements outside of react app
(including root div)
this is to get a holy-grail flexbox layout */
html,
body,
#root {
  font-family: 'Source Code Pro', monospace;
  font-size: 16px;
  height: 100%;
  margin: 0;
  overflow: hidden;
  width: 100%;
}

@media (--mobile) {
  html,
  body,
  #root {
    font-size: 12px;
  }
}

body {
  background: var(--blue2);
  color: var(--gray4);
}

a {
  color: var(--blue4);
  cursor: pointer;
  text-decoration: underline;
  transition: color .3s;
}
a:hover {
  color: var(--blue5);
}

label {
  display: block;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
}

img {
  display: block;
}

@keyframes jolt {
  0% {
    transform: rotate(1deg);
  }
  25% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(0.25deg);
  }
  75% {
    transform: rotate(-0.25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.animJolt {
  animation-name: jolt;
  animation-timing-function: var(--ease-out);
  animation-duration: .5s;
}
