
@import '../../styles/core/media.css';

.arrowBtn {
  border: 1px solid var(--gray4);
  border-radius: 4px;
  color: var(--gray4);
  padding: .5rem 1rem;
  transition: border-color .3s, color .3s;
}
.arrowBtn:hover {
  border-color: var(--blue5);
  color: var(--blue5);
}

.arrowBtn.isActive,
.arrowBtn.isActive:hover {
  background: var(--blue4);
  color: var(--blue1);
}

.arrowBtn.isDisabled,
.arrowBtn.isDisabled:hover {
  border-color: var(--gray2);
  color: var(--gray2);
}

.arrow {
  display: block;
  height: 0;
  transition: border-color .3s;
  width: 0;
}

.arrow.left,
.arrow.right {
  border-top: .33rem solid transparent;
  border-bottom: .33rem solid transparent;
}

.arrow.right {
  border-left: 0.5rem solid var(--gray4);
}
.arrowBtn:hover .arrow.right {
  border-left-color: var(--blue5);
}
.arrowBtn.isDisabled .arrow.right,
.arrowBtn.isDisabled:hover .arrow.right {
  border-left-color: var(--gray2);
}

.arrow.left {
  border-right: 0.5rem solid var(--gray4);
}
.arrowBtn:hover .arrow.left {
  border-right-color: var(--blue5);
}
.arrowBtn.isDisabled .arrow.left,
.arrowBtn.isDisabled:hover .arrow.left {
  border-right-color: var(--gray2);
}

.arrow.up,
.arrow.down {
  border-left: .33rem solid transparent;
  border-right: .33rem solid transparent;
}

.arrow.up {
  border-bottom: 0.5rem solid var(--gray4);
}

.arrowBtn:hover .arrow.up {
  border-bottom-color: var(--blue5);
}

.arrowBtn.isDisabled .arrow.up,
.arrowBtn.isDisabled:hover .arrow.up {
  border-bottom-color: var(--gray2);
}

.arrow.down {
  border-top: 0.5rem solid var(--gray4);
}

.arrowBtn:hover .arrow.down {
  border-top-color: var(--blue5);
}

.arrowBtn.isDisabled .arrow.down,
.arrowBtn.isDisabled:hover .arrow.down {
  border-top-color: var(--gray2);
}
