
@import '../../styles/core/media.css';

.container {
  padding: 1rem;
}

.input {
  align-items: center;
  display: flex;
  height: calc(60px + 1rem);
  justify-content: center;
}

.input.noFlex { 
  display: block;
}

.container.small .input {
  height: calc(45px + 1rem);
}

@media (--desktop) {
  .container.small .input {
    height: calc(50px + 1rem);
  }
}
