.fill {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.flexFill {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.flex {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flex.column {
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  width: 100%;
}

.flex.between {
  justify-content: space-between;
}

.rel {
  position: relative;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.vcenterer {
  position: relative;
  text-align: center;
}
.vcenterer:before {
  content: '';
  display: inline-block;
  font-size: 0;
  margin: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.vcenteree {
  display: inline-block;
  max-width: 94%;
  position: relative;
  vertical-align: middle;
}

.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.pageWidth {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 480px;
  padding: 0 1rem;
  position: relative;
  width: 100%;
}

.pageWidth.narrow {
  max-width: 504px;
  padding: 0 3rem;
}

.pageBody {
  flex-grow: 1;
  width: 100%;
}

.pageFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  background-color: var(--blue1);
  border-top: 1px solid var(--blue3-5);
  padding: 0.5rem 0 1.5rem;
  position: relative;
  width: 100%;
}

.textCenter {
  text-align: center;
}

.ui {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.btnReset {
  appearance: none;
  background: none;
  border: 0;
}
.btnReset:focus {
  outline: none;
}

.fade {
  opacity: 0;
  transition: opacity .25s ease-out, visibility .25s ease-out;
  visibility: hidden;
}

.fade.fadeIn {
  opacity: 1;
  visibility: visible;
}
