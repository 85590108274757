/*
  app
*/
@import 'styles/core/media';

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200..900&display=swap');

.layout {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.bg {
  background-position:  center center;
  background-image: var(--bg-url);
  background-size: cover;
  opacity: var(--bg-opacity);
}

.header {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
}

.main {
  display: flex;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

/*
  nav
*/

.heading {
  margin: 0 .75rem 0 0;
}
.logo {
  height: 24px;
  width: 72px;
  opacity: 0.8;
}
@media (--desktop) {
  .logo {
    height: 30px;
    width: 90px;
  }
}


.nav {
  align-items: center;
  display: flex;
  position: relative;
}

.navLink {
  color: var(--blue4);
  display: block;
  font-size: 1rem;
  margin: 0 0.5rem;
  text-align: center;
  text-transform: uppercase;
}

.navLink.navLinkActive {
  color: var(--gray4);
}
