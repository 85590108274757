
@import '../../../styles/core/media.css';

@media (--desktop) {
  .component {
    margin-bottom: -6rem;
    transform: translateY(-60px) rotate(270deg);
  }
}

.container {
  background: var(--blue3);
  border: 1px solid var(--gray2);
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.group {
  overflow: hidden;
  position: relative;
  transition: transform .6s var(--ease-in-out);
  width: 200%;
}

.group.stepPage0.padPage1 {
  transform: translateX(-50%);
}

.group.stepPage1.padPage1 {
  transform: translateX(-50%) translateY(-50%);
}

.group.stepPage1.padPage0 {
  transform: translateY(-50%);
}

.controls {
  padding: .5rem 0;
}
